/**
 * eslint-disable react-hooks/exhaustive-deps
 * 
 * @format
 */
import { GetStaticProps } from "next";
import { Layout } from "components";
import api from "@framework/api";
import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { dateSpanishShort } from "../framework/lib/dateFormat";
import {
  ExchangeHouses,
  Converter,
  Quotation,
  InterbankExchange,
  CardBlog,
  ExchangeEquivalents,
  CounterfeitBanknotes,
  BasicInformation,
  Questions,
} from "@components/ui";
import { ExchangeValue, Interbank, InterbankExchangeRate, PostsBlog } from "@framework/types/home";
import { IChart } from "@framework/types/chart";
import { CustomWindow, PageView } from "@framework/types/site";
import AdsSlotRepeatable from "@components/AdsSlotRepeatable/AdsSlotRepeatable";
import dynamic from "next/dynamic";
import Link from "next/link";
import { buttonPage } from "@framework/lib/gtm";
import Clock from "@components/ui/icons/Clock";
import BannerEnVivo from "@components/BannerEnVivo";
import ConverterDinamic from "@components/ui/Converter/Converter.dinamic";
import { ConverterData } from "@framework/data/converter";
import { HouseOnly } from "@framework/types/exchangehouses";
import BannerHome from "@components/BannerHome";
import QuotationMobile from "@components/ui/Quotation/QuotationMobile";
// import { OnlineExchange } from '@framework/types/home';
import io from "socket.io-client";
import { ExchangeHousesV3 } from "@components/ui/ExchangeHouses";
import Script from "next/script";
import s from "../styles/home.module.css";

const DynamicChartsVariation = dynamic(() => import("@components/ui/Charts/ChartsVariation"), {
  loading: () => <p>Cargando...</p>,
});


interface HomeProps {
  menus: Array<any>;
  onlineExchangeHouses: Array<any>;
  configCalculator: Boolean;
  exchangeSunat: Interbank;
  exchangeBanks: InterbankExchangeRate;
  //   houseOnly: any;
  posts: PostsBlog[];
  chart: IChart[] | null;
  ads: any;
  exchangeValues: ExchangeValue;
  ticker: any;
  exchangeCanada: Interbank;
  exchangeHousesCalculator: Array<any>;
  dataCaja: any;
  message: any;
}

export interface ExchangeHouseProps {
  directorio_id: string;
  rates: {
    buy: {
      currency: string;
      cost: string;
      variation: number;
      signal: string;
    };
    sale: {
      currency: string;
      cost: string;
      variation: number;
      signal: string;
    };
  };
}

declare let window: CustomWindow;

export default function Home({
  menus,
  onlineExchangeHouses,
  configCalculator,
  exchangeSunat,
  exchangeBanks,
  //   houseOnly,
  posts,
  exchangeValues,
  ticker,
  chart,
  ads,
  exchangeCanada,
  exchangeHousesCalculator,
  dataCaja,
  message,
}: HomeProps) {
  const [valueCal, setValueCal] = useState(0);
  const [currency, setCurrency] = useState("");
  const [estadoCal, setEstadoCal] = useState("");
  const [dateLocal, setdateLocal] = useState("");
  const buyDolar = parseFloat(exchangeSunat.buy.cost);
  const [exchangeDolar, setExchangeDolar] = useState(exchangeValues.calle);
  const [exchangeEuro, setExchangeEuro] = useState(exchangeValues.euro);
  const [exchangeYuan, setExchangeYuan] = useState(exchangeValues.Yuan);
  const [exchangeLibra, setExchangeLibra] = useState(exchangeValues.Libra);
  const [exchangeHouses, setExchangeHouses] = useState(onlineExchangeHouses);
  const [activeTab, setActiveTab] = useState("1");
  const [displayedColors, setDisplayedColors] = useState<number[]>([]);
  const [currentData, setCurrentData] = useState<ConverterData>();
  const [houseOnly, setHouseOnly] = useState<HouseOnly | undefined>();
  const [showBannerHome, setShowBannerHome] = useState(true);
  // const [exchangeHouses, setExchangeHouses] = useState<OnlineExchange[]>([]);
  const [isSocketData, setIsSocketData] = useState<ExchangeHouseProps | null>(null);
  const [conected, setConected] = useState<boolean>(false);
  const hasMounted = useRef(false);
  const [sizeDesktop, setSizeDesktop] = useState(false);

  const textRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [animationDuration, setAnimationDuration] = useState(90)
  
  exchangeBanks.caja = dataCaja;

  const dataConverter = exchangeHousesCalculator
    .filter((item: any) => item.dynamic_calculator)
    .map((item: any) => {
      const data = {
        id: item.id,
        color: item.dataConverter.styleHeadConverter,
        title: item.dataConverter.title,
        site: item.dataConverter.url,
        logo: item.dataConverter.logo,
        text: item.dataConverter.styleFontConverter,
        text2: item.dataConverter.styleFontConverter2,
      };
      return data;
    });

  const valueCalcular = (result: number, currencySent: string, estado: string) => {
    if (estado == "convertir") {
      setValueCal(result);
      setCurrency(currencySent);
      setEstadoCal("convertir");
    } else if (estado == "regresar") {
      setEstadoCal("regresar");
      setValueCal(result);
      setCurrency(currencySent);
    }
  };

  const handleButtonTabs = (id: string) => () => {
    buttonPage(id);
    setActiveTab(id);
    if (id == "1" || id == "2") {
      const newExchangeHouses = onlineExchangeHouses.filter((item: any) => {
        return item.page == id;
      });
      setExchangeHouses(newExchangeHouses);
    }
  };


  const sizeScreen = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth > 760) {
        return setSizeDesktop(true);
      } else {
        return setSizeDesktop(false);
      }
    }
  }

  useEffect(() => {
    sizeScreen();
    const newExchangeHouses = onlineExchangeHouses.filter((item: any) => {
      return item.page === "1";
    });
    setExchangeHouses(newExchangeHouses);
    setdateLocal(dateSpanishShort( new Date()));
    if (typeof window !== "undefined") {
      const storedColors = localStorage.getItem("displayedColors");
      const parsedColors = storedColors ? JSON.parse(storedColors) : [];
      setDisplayedColors(parsedColors);
    }
  }, [ads]);

  const getRandomColor = () => {
    if (typeof window !== "undefined") {
      const remainingColors = dataConverter
        .map((color) => color.id)
        .filter((id) => !displayedColors?.includes(Number(id)));
      if (remainingColors.length === 0) {
        localStorage.setItem("displayedColors", JSON.stringify([]));
        setDisplayedColors([0]);
        return;
      }
      const randomIndex = Math.floor(Math.random() * remainingColors.length);
      const selectedColor = remainingColors[randomIndex];
      const updatedDisplayedColors = [...displayedColors, selectedColor];
      setDisplayedColors(updatedDisplayedColors.map(Number));
      localStorage.setItem("displayedColors", JSON.stringify(updatedDisplayedColors));
    }
  };


  useEffect(() => {
    getRandomColor();
    const idData = localStorage.getItem("displayedColors");
    let lastBannerState = localStorage.getItem("lastBannerState"); // Obtener el último estado del banner
    if (idData && idData.length > 0) {
      const data = dataConverter.filter((item) => item.id === JSON.parse(idData)[0]);
      const currentData = data[0]; // Asignar data[0] a una variable local
      setCurrentData(currentData); // Establecer el estado con la variable local
      const dataHouse = exchangeHousesCalculator.filter((item: any) => item.id === JSON.parse(idData)[0]);
      const houseOnly = dataHouse.filter((item) => item.dynamic_calculator); // Asignar dataHouse[0] a una variable local
      setHouseOnly(houseOnly[0]); // Establecer el estado con la variable local
    }
    // Generar un valor aleatorio para decidir qué banner mostrar
    let randomValue = Math.random();
    // Si el último estado del banner es igual al nuevo estado generado aleatoriamente, generar un nuevo estado aleatorio
    while (lastBannerState === randomValue.toString()) {
      randomValue = Math.random();
    }
    lastBannerState = randomValue.toString(); // Actualizar el último estado del banner
    localStorage.setItem("lastBannerState", lastBannerState); // Almacenar el último estado del banner
    setShowBannerHome(randomValue < 0.5); // Cambiar el banner aleatoriamente
    const script = document.createElement('script');
    script.src = "https://jsc.mgid.com/site/947042.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const getData = async () => {
    try {
      const response = await fetch('/api/dataExchangeHouse');
      const data = await response.json();
      setExchangeHouses(data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
        const socket = io('https://ws.cuantoestaeldolar.com.pe/');
        getData();

        socket.on('connect', () => {
            setConected(true);
            console.log('Conectado al servidor');
        });

        socket.on('exchangehouses', (data: ExchangeHouseProps) => {
            console.log('data exchange', data);
            const dataFilter = exchangeHouses.filter((item) => item.id === data.directorio_id);
            console.log('dataFilter', dataFilter);
            setIsSocketData(data);
            console.log('data isSocketData', data);
        });

        socket.on('disconnect', () => {
            console.log('Desconectado del servidor');
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('exchangehouses');
            socket.disconnect();
        };
    }, 45000); 

    return () => {
        clearTimeout(timeoutId);
    };
  }, []);

    useEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const textWidth = textRef.current.offsetWidth
      const duration = (textWidth / containerWidth) * 10 // Ajustar la velocidad aquí
      setAnimationDuration(duration > 20 ? duration : 30)
    }
  }, [message])

  return (
    <>
      {showBannerHome ? <BannerHome /> : <BannerEnVivo />}
      <div className="mx-3 md:my-0">
        <div className="flex justify-between">
          <h1 className="md:pr-20 text-[32px] md:text-32px] font-bold leading-10 -tracking-wide mt-3 mb-1.5 ">
            {sizeDesktop ? "Precio del dólar hoy en Perú" : "Precio del dólar en Perú"}
          </h1>
          <div className="hidden md:flex md:my-4 ">
            <button
              className={`border border-[#0096A6] bg-white rounded-full px-2 h-[35px] mx-[26px]`}
              aria-label="boton cerrar banner"
            >
              <h4 className="text-[#0096A6] text-xs font-medium tracking-wider px-3">
                <Link
                  href="/digitaliza-tu-casa-de-cambio"
                >
                  DIGITALÍZATE...
                </Link>
              </h4>
            </button>
            <button
              className={`border border-[#0096A6] bg-[#0096A6] rounded-full px-2 h-[35px]`}
              aria-label="boton cerrar banner"
            >
              <h4 className="text-white text-xs font-medium tracking-wider px-3">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc55eO4gv0pY8GzfIY5eQX8T7_nfaLzQxf85U44ukrshY2GKA/viewform"
                  target="_blank"
                  rel="noreferrer"
                >
                  PUBLICIDAD
                </a>
              </h4>
            </button>
          </div>
        </div>
        <div className="flex justify-between text-black">
          <div className="flex justify-start md:w-1/2 my-auto md:absolute">
            <div className="my-auto">
              <Clock width={18} height={18} fill="#000000" />
            </div>
            <p className="ml-1 text-sm md:text-[16px] md:text-lg ">{dateLocal}</p>
          </div>
          <div className="flex justify-end md:hidden">
            <button
              className={`border border-[#0096A6] bg-white rounded-full px-2 h-[29px] mx-[10px]`}
              aria-label="boton cerrar banner"
            >
              <h4 className="text-[#0096A6] text-[10px] font-medium tracking-wider px-1.5">
                <Link
                  href="/digitaliza-tu-casa-de-cambio"
                >
                  DIGITALÍZATE
                </Link>
              </h4>
            </button>
            <button
              className={`border border-[#0096A6] bg-[#0096A6] rounded-full px-2 h-[29px]`}
              aria-label="boton cerrar banner"
            >
              <h4 className="text-white text-[10px] font-medium tracking-wider px-2">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc55eO4gv0pY8GzfIY5eQX8T7_nfaLzQxf85U44ukrshY2GKA/viewform"
                  target="_blank"
                  rel="noreferrer"
                >
                  PUBLICIDAD
                </a>
              </h4>
            </button>
          </div>
        </div>
      </div>
      <div className="mx-3 md:flex ">
        <div className="md:items-center w-full md:mt-12 md:hidden ">
          <QuotationMobile
            exchangeSunat={exchangeSunat}
            exchangeDolar={exchangeDolar}
            exchangeEuro={exchangeEuro}
            valueCal={valueCal}
            currency={currency}
            title="cotización"
          />
        </div>
        <div className="md:items-center md:w-1/2  hidden md:flex">
          <Quotation
            title={"cotización"}
            exchangeSunat={exchangeSunat}
            exchangeDolar={exchangeDolar}
            exchangeEuro={exchangeEuro}
            exchangeYuan={exchangeYuan}
            exchangeLibra={exchangeLibra}
            exchangeCanada={exchangeCanada}
            valueCal={valueCal}
            currency={currency}
            page="home"
          />
        </div>
        <div className="pb-4 md:w-1/2">
          <ConverterDinamic
            valueCalcular={(result, currencySent, estado) => valueCalcular(result, currencySent, estado)}
            houseOnly={houseOnly}
            exchangeDolar={exchangeDolar}
            currentConverter={currentData}
          />
        </div>
      </div>
      <div className='flex items-end mx-1 md:mx-3 pt-4 md:pb-4 md:pt-8 overflow-x-auto'>
				<button
					id='boton_home_pageTab_casasOnline_1'
					className={`${
						activeTab === '1'
							? s.button_tabs_active
							: s.button_tabs_inactive
					} ${s.button_tabs} mr-2 md:mr-4`}
					onClick={handleButtonTabs('1')}>
					Cambio Online
				</button>
				{onlineExchangeHouses.some((item) => item.page == '2') && (
					<div className='text-center'>
						<p className='w-38 mx-auto text-purple_ced-100'>
							¡Nuevo!
						</p>
						<button
							id='boton_home_pageTab_casasOnline_2'
							className={`${
								activeTab === '2'
									? s.button_tabs_active
									: s.button_tabs_inactive
							} ${s.button_tabs} mx-2 md:mx-4`}
							onClick={handleButtonTabs('2')}>
							Casas de cambio
						</button>
					</div>
				)}
				<button
					id='boton_home_pageTab_bancos'
					className={`${
						activeTab === '3'
							? s.button_tabs_active
							: s.button_tabs_inactive
					} ${s.button_tabs} ml-2 md:ml-4`}
					onClick={handleButtonTabs('3')}>
					Bancos
				</button>
			</div>  
      <div className="mx-3 mt-4">

      {message && message[0].active ? (
        <div>
        <div className="md:flex block mb-3 items-center justify-between">
          <h2 className="text-black font-bold md:text-2xl mr-2 w-full md:w-80 text-lg mt-2 hover:text-green_ced-300 hidden md:flex">
            <Link href={"/cambio-de-dolar-online"}>Casas de Cambio Online »</Link>
          </h2>
          <h2 className="text-black font-bold md:text-2xl mr-2 w-full md:w-44 text-lg mt-2 hover:text-green_ced-300 md:hidden">
            <Link href={"/cambio-de-dolar-online"}>Casas de Cambio Online »</Link>
          </h2>
          <p className="text-sm md:hidden">
          Despliega para ver los horarios de banco a banco. Diferidas en todas las empresas
          </p>
          <div className={`${s.modal_content} md:mr-3 relative flex items-center mt-4 w-full md:w-3/4 overflow-hidden mb-6`}>
            <div
              className="flex whitespace-nowrap p-2"
              ref={textRef}
              style={{ animation: `scroll-left ${animationDuration}s linear infinite` }}
            >
              {message.map((item: any, index: number) => (
                <div key={index} className="flex items-center mx-4">
                  {item.type === 'texto' ? (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-xs"
                      style={{ color: item.color }}
                    >
                      {item.title}
                    </a>
                  ) : item.type === 'imagen' ? (
                    <div className="flex-shrink-0" style={{ width: `${item.width}px` }}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <Image
                          src={item.img}
                          width={item.width}
                          height={item.height ? item.height : 40}
                          alt={item.altImg}
                          layout="responsive"
                        />
                      </a>
                    </div>
                  ) : null}
                </div>
              ))}
              {message.map((item: any, index: number) => (
                <div key={`repeat-${index}`} className="flex items-center mx-4">
                  {item.type === 'texto' ? (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-xs"
                      style={{ color: item.color }}
                    >
                      {item.title}
                    </a>
                  ) : item.type === 'imagen' ? (
                    <div className="flex-shrink-0" style={{ width: `${item.width}px` }}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <Image
                          src={item.img}
                          width={item.width}
                          height={item.height ? item.height : 40}
                          alt={item.altImg}
                          layout="responsive"
                        />
                      </a>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
      ) : (
        <div>
          <h2 className="text-black font-bold text-2xl mt-2 hover:text-green_ced-300">
            <Link href={"/cambio-de-dolar-online"}>Cambio online »</Link>
          </h2>
          <div className="block md:flex md:justify-between w-full my-3">
            <div className="self-center text-black text-lg font-normal">
              El portafolio de empresas de cambio online están verificadas.
            </div>
            <div className="flex justify-around md:justify-between my-3 md:my-0 md:w-[40%]">
              <div>
                <Image
                  src={"https://s3-ced-uploads-01.s3.amazonaws.com/1712637083240-Component 524.svg"}
                  width={160}
                  height={42}
                  alt="Logo SBS"
                />
              </div>
              <div>
                <Image
                  src={"https://s3-ced-uploads-01.s3.amazonaws.com/1712636939243-Group 48095911.svg"}
                  width={121}
                  height={34}
                  alt="Logo Sunat"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        @keyframes scroll-left {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
        </div>
        {/* <div className="flex justify-center py-4 md:mt-0">
				<button className=" uppercase bg-white border-solid border-2 border-[#0096A6] hover:border-[#205985] rounded-full w-36 h-9 ">
				<h4 className="text-secondary text-sm hover:text-[#205985]">compartir</h4>
				</button>
			</div> */}
      {activeTab !== '3' && exchangeHouses && (
        <div className="mx-1 md:mx-3">
          {/* <ExchangeHouses
            page={"home"}
            onlineExchangeHouses={exchangeHouses}
            valueCal={valueCal}
            currency={currency}
            section={"ExangeHouse"}
          /> */}
          <ExchangeHousesV3
          page={'home'}
          onlineExchangeHouses={exchangeHouses}
          valueCal={valueCal}
          currency={currency}
          dataSocket={isSocketData}
        />
        </div>
      )}
      {activeTab === '3' && (
				<div className='mx-1 md:mx-3'>
					<InterbankExchange
						page={'home'}
						section={'ExangeHouse'}
						exchangeBanks={exchangeBanks}
						valueCal={valueCal}
						currency={currency}
					/>
				</div>
			)}
      {/* <AdsSlot
					div='cuantoestaeldolar_pe_970x250_banner_responsive_1'
					className='billboard-height text-center'
				/> */}
      {/* <div id="video_ad" /> */}
      <AdsSlotRepeatable
        div="cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive"
        className="billboard-height text-center"
      />
      <div className="mx-3 my-4 ">
        <div className="flex md:justify-center">
          <h2 className="text-black font-bold text-2xl pb-4 hover:text-green_ced-300">
            <Link href={"/dolar-interbancario"}>Precio del dólar interbancario »</Link>
          </h2>
        </div>
        <p className="align-self: center text-black text-lg font-normal py-4">
          Precios referenciales. Pueden estar sujetos a cambios por hora de publicación y políticas de gestión de
          clientes. Despliega para ver los horarios.
        </p>
        {/* <div className="flex justify-center py-4">
			<button className=" uppercase bg-white border-solid border-2 border-[#0096A6] hover:border-[#205985] rounded-full w-36 h-9 md:bg-[#009688] md:hover:bg-[#027A6F] md:border-none">
				<h4 className="text-secondary text-sm md:text-white md:hover:text-white hover:text-[#205985]">compartir</h4>
			</button>
			</div> */}
      </div>
      <div className="mx-3">
        <InterbankExchange page={"home"} exchangeBanks={exchangeBanks} valueCal={valueCal} currency={currency} />
      </div>
      <AdsSlotRepeatable
        div="cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive"
        className="billboard-height text-center"
      />
      <div className="mx-3 my-4">
        <div className="md:flex md:justify-center">
          <h2 className="text-black font-bold text-2xl pb-4 hover:text-green_ced-300">
            <Link href={"/historial"}>Variación del precio del dólar en Perú »</Link>
          </h2>
        </div>
        <div className="hidden md:flex md:justify-center">
          <p className="text-black text-lg font-normal">
            Sigue el tipo de cambio en línea y encuentra el mejor momento para comprar o vender dólares en Perú.
          </p>
        </div>
        {/* <div className="flex justify-center py-4">
				<button className="bg-[#009688] hover:bg-[#027A6F] uppercase w-40 h-9 px-4 rounded-3xl shadow-sm shadow-black text-sm text-white font-medium ">
				compartir
				</button>
			</div> */}
      </div>
      <div className="mx-3">
        <div className="flex justify-between m-auto mt-[30px]">
          <div id="dt-bloomberg1"></div>
          <div>
            <span className="inline-block w-[10px] h-[10px] bg-third2 rounded-[5px] mr-[5px]"></span>
            Compra
          </div>
        </div>
        {chart && <DynamicChartsVariation data={chart} />}
        {/*{chart && (
			<div className={`${s.chartWithOverlay}`}>
				<div id="chart_div" className="w-full h-[30rem] my-4 pr-4 md:pr-12"></div>
				{draw1 &&
				<div className={`${s.overlay}`}>
					<div className="bg-purple_ced-100 text-white px-1 md:px-5  rounded my-3">{priceLast}</div>
				</div>
				}
			</div>       
			)} */}
      </div>
      <AdsSlotRepeatable
        div="cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive"
        className="billboard-height text-center"
      />
      {posts && (
        <>
          <div className="mx-3 my-6 pr-28 md:pr-0 md:flex md:justify-center">
            <h2 className="text-black font-bold text-2xl hover:text-green_ced-300">
              <Link href={"/blog"}>Noticias sobre el precio del dólar »</Link>
            </h2>
          </div>
          <CardBlog posts={posts} />
        </>
      )}
      <div data-type="_mgwidget" data-widget-id="1634861"></div>
      <div className="mx-3 md:flex ">
        <div className="w-full md:w-3/5">
          <div className="mt-8 ">
            <ExchangeEquivalents buy={buyDolar} />
          </div>
        </div>
        <div className="w-full md:w-2/5">
          <div className="mt-8">
            <CounterfeitBanknotes />
          </div>
        </div>
      </div>
      <AdsSlotRepeatable
        div="cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive"
        className="billboard-height text-center"
      />
      <div className="mx-3">
        <BasicInformation />
      </div>
      <AdsSlotRepeatable
        div="cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive"
        className="billboard-height text-center"
      />
      <div className="mx-3">
        <Questions />
      </div>
      <AdsSlotRepeatable
        div="cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive"
        className="billboard-height text-center"
      />
      <Script id="mgc-widget" strategy="afterInteractive">
        {`
          (function(w, q){
            w[q]=w[q]||[];w[q].push(["_mgc.load"])})(window, "_mgq");
        `}
      </Script>
    </>
  );
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const { data: menu } = await api.getmenu();
  const { data: onlineExchangeHouses } = await api.getExchangeHouses({ status: 1 });
  const { data: exchangeHousesCalculator} = await api.getExchangeHouses();
  const { data: ticker } = await api.getExchangeHouses({ ticker: 1 });
  //   const { data: houseOnly } = await api.getOnlineExchangeId("8f0569e6-c721-4266-8f36-e074e50c991a");
  const { data: exchangeSunat } = await api.getOnlineExchange({ entity: "sbs" });
  const { data: exchangeBanks } = await api.getOnlineExchange({ entity: "interbank" });
  const { data: dataCaja } = await api.getDataCaja();
  const { data: exchangeCanada } = await api.getExchange("canada");

  const { data: exchangeValues } = await api.getOnlineExchange();
  const { data: chart } = await api.getChart();

  // TODO:
  const { data: posts } = await api.getPosts();
  const { data: dataBuy } = await api.getBuy();
  //   const { data: chart } = await api.getChart();
  const { data: ads } = await api.getAds();
  const { data: clever } = await api.clever();
  const { data: videos } = await api.getVideos();
  const { data: message } = await api.getMessages();

  const dataAds = ads ? ads : [];
  const cambioOnline = onlineExchangeHouses ? onlineExchangeHouses : [];

  const configCalculator = true;
  // Filtra los elementos undefined de posts.items

  return {
    revalidate: 60,
    props: {
      pageView: PageView.HOME,
      menus: menu, // Valor predeterminado si menu es undefined
      onlineExchangeHouses: cambioOnline, // Valor predeterminado si onlineExchangeHouses es undefined
      configCalculator,
      ticker,
      exchangeSunat: exchangeSunat || null, // Valor predeterminado si exchangeSunat es undefined
      exchangeBanks: exchangeBanks || null, // Valor predeterminado si exchangeBanks es undefined
      exchangeValues: exchangeValues || null, // Valor predeterminado si exchangeValues es undefined
      //   houseOnly: houseOnly || null, // Valor predeterminado si houseOnly es undefined
      posts: posts || null,
      dataBuy: dataBuy || null, // Valor predeterminado si dataBuy es undefined
      chart: chart ? chart : [], // Valor predeterminado si chart es undefined
      ads: dataAds, // Valor predeterminado si ads es undefined
      videos: videos || null, // Valor predeterminado si videos es undefined
      clever: clever ? clever.ads_status : null, // Valor predeterminado si clever es undefined
      exchangeCanada,
      dataCaja : dataCaja || null,
      exchangeHousesCalculator,
      message : message || null,
    },
  };
};

Home.Layout = Layout;
